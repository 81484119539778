<template>
  <nav>
    <v-app-bar dark app style="background-color: #0b2559">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase">
        <!-- <span class="font-weight-light">AAE</span> -->
        <span v-if="$store.state.basicSetUpData.logged_in_user">{{
          $store.state.basicSetUpData.logged_in_user.full_name
        }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon left>expand_more</v-icon>
            <span>Menu</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="link in links"
            :key="link.text"
            router
            :to="link.route"
            active-class="border"
          >
            <!-- <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action> -->
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text v-on:click="logout" class="logout">
        <span>Logout</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      dark
      app
      style="background-color: #0b2559"
    >
      <v-layout column align-center>
        <v-flex class="" style="background: #e4e4e4">
          <div class="white--text subheading mt-1 text-center">
            <img src="@/assets/golo-crm-logo.png" alt="" style="width: 36%" />
          </div>
        </v-flex>
        <v-flex class="mt-4 mb-4">
          <!-- <Popup /> -->
        </v-flex>
      </v-layout>
      <!-- <v-list flat>
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
          active-class="border"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->

      <v-list>
        <div v-for="(link, i) in links" :key="i">
          <v-list-item
            v-if="!link.subLinks"
            :key="i"
            :to="link.route"
            active-class="border"
            router
            class="v-list-item"
          >
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else :key="link.text" active-class="border" no-action>
            <template v-slot:activator>
              <v-list-item class="px-0">
                <v-list-item-action>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ link.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              v-for="sublink in link.subLinks"
              :to="sublink.route"
              :key="sublink.text"
              active-class="border"
            >
              <v-list-item-action>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ sublink.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script>
// import Popup from './Popup.vue'
import util from "@/util/util";
export default {
  data: () => ({
    drawer: true,
    links: [
      { icon: "dashboard", text: "Dashboard", route: "/" },
      { icon: "description", text: "Document", route: "/document" },
      // { icon: "folder", text: "Contacts", route: "/contacts" },
      // { icon: "work", text: "Events", route: "/events" },
      // { icon: "sms", text: "Notification", route: "/notification" },
      // { icon: "person", text: "Reports", route: "/reports" },
      { icon: "folder", text: "Files", route: "/files" },
      // { icon: "person", text: "Company Setting", route: "/company-setting" },
      // { icon: "person", text: "Product", route: "/product" },
      // { icon: "person", text: "Quotation", route: "/quotation" },
      // { icon: "person", text: "PO", route: "/po" },

      {
        icon: "work",
        text: "Customer",
        route: "/contacts",
        subLinks: [
          { icon: "folder", text: "Contacts", route: "/contacts" },
          { icon: "work", text: "Events", route: "/events" },
          { icon: "sms", text: "Notification", route: "/notification" },
          {
            icon: "person",
            text: "Setting",
            route: "/company-setting",
          },
        ],
      },

      // test
      {
        icon: "work",
        text: "Job",
        route: "/jobs",
        subLinks: [
          { icon: "work", text: "Jobs", route: "/jobs" },
          { icon: "work", text: "Jobs Priority", route: "/job/priority" },
          { icon: "work", text: "Jobs Type", route: "/job/type" },
          { icon: "work", text: "Jobs Status", route: "/job/status" },
        ],
      },
      {
        icon: "work",
        text: "Finance",
        route: "/invoice",
        subLinks: [
          { icon: "book", text: "Invoice", route: "/invoice" },
          { icon: "person", text: "Quotation", route: "/quotation" },
        ],
      },

      {
        icon: "work",
        text: "Settings",
        route: "/invoice",
        subLinks: [
          { icon: "people", text: "User", route: "/users" },
          { icon: "person", text: "Role", route: "/roles" },
          { icon: "book", text: "Logs", route: "/logs" },
        ],
      },

      // test

      // { icon: "book", text: "Invoice", route: "/invoice" },
      // { icon: "person", text: "Task Management", route: "/task" },
      // { icon: "people", text: "User", route: "/users" },
      // { icon: "person", text: "Role", route: "/roles" },
      // { icon: "book", text: "Logs", route: "/logs" },
    ],
  }),
  components: {
    // Popup
  },
  methods: {
    logout() {
      // sessionStorage.removeItem("token");
      // this.$router.push({ name: "Login" });
      util.http
        .post("logout")
        .then((res) => {
          console.log(res);
          sessionStorage.removeItem("token");
          this.$router.push({ name: "Login" });
        })
        .catch(() => {
          sessionStorage.removeItem("token");
          this.$router.push({ name: "Login" });
        });
    },
  },
};
</script>
<style scoped>
.border {
  /* border-left: 4px solid #0ba518; */
  background-color: #576ea9;
  color: #fff !important;
}
.logout {
  background: #ff0000;
}
</style>
