<template>
    <div>
      <Navbar/>
    <v-main class="ma-4">
      <router-view></router-view>
    </v-main>  
    </div>
</template>
<script>
import Navbar from '@/components/Navbar'
export default {
    components: {
   Navbar
  },
}
</script>